export const __registerBlazorGoogleMapsAutocompleteHandler = function (dotnet) {
    window.agilitas.googleMaps.initialize(function ({ name: location, lat: latitude, lng: longitude }) {
        console.debug('invoking OnLocationAutoComplete', location, latitude, longitude);
        dotnet.invokeMethodAsync('OnLocationAutoComplete', location, latitude, longitude)
    });
};

export const __blazorRecaptcha = async function (token) {
    await new Promise((resolve) => {
        grecaptcha.ready(function() {
            resolve();
        });
    });

    return await grecaptcha.execute(token, { action: 'submit' });
}