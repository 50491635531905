﻿import { __registerBlazorGoogleMapsAutocompleteHandler, __blazorRecaptcha } from './frontend/blazor';

window.agilitas = window.agilitas || {};
window.agilitas.vacancies = {
	__registerBlazorGoogleMapsAutocompleteHandler,
	__blazorRecaptcha
};




